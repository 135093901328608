<template>
  <v-container style="height: 100%" fluid>
    <v-row style="height: 100%" justify="center" align-content="center">
      <v-col cols="12" lg="4">
        <v-alert
          light
          :value="logInError ? true : false"
          type="error"
          transition="scale-transition"
          >{{ logInError }}</v-alert
        >
        <v-form
          v-model="formIsValid"
          @submit.prevent="login(username, password)"
        >
          <v-card class="elevation-12">
            <v-card-text>
              <v-text-field
                label="Username"
                name="username"
                :prepend-icon="mdiAccount"
                type="text"
                :rules="userNameRules"
                v-model="username"
              ></v-text-field>
              <v-text-field
                id="password"
                label="Password"
                name="password"
                :prepend-icon="mdiLock"
                type="password"
                :rules="passwordRules"
                v-model="password"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" :disabled="!formIsValid"
                >Login</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiAccount, mdiLock } from '@mdi/js';
import { mapState } from 'vuex';

export default {
  data: () => ({
    mdiAccount,
    mdiLock,
    formIsValid: false,
    username: '',
    password: '',
    userNameRules: [(username) => !!username || 'Username is required'],
    passwordRules: [(password) => !!password || 'Password is required'],
  }),

  computed: {
    ...mapState({
      logInError: (state) =>
        state.users.logInError
          ? state.users.logInError.non_field_errors[0]
          : state.users.logInError,
    }),
  },

  methods: {
    login(username, password) {
      this.$store.dispatch('doLogin', { username, password });
    },
  },
};
</script>
